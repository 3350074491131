<div class="flex flex-1 flex-col gap-2">
  <label>
    <span class="text-sm font-medium text-gray-700">{{ this.label() }}</span>
  </label>
  <div class="relative">
    <input
      [ngClass]="{
        '!pr-7 !ring-1 !ring-error-border':
          (this.control().touched || this.dirty()) && this.control().invalid,
      }"
      class="!mb-0 w-full bg-white font-normal text-gray-500"
      type="text"
      [formControl]="control()"
    />

    @if ((this.control().touched || this.dirty()) && this.control().invalid) {
      <fa-icon
        class="fa-sm absolute right-2 top-1/2 -translate-y-1/2 transform text-error-text"
        [icon]="['far', 'circle-exclamation']"
      ></fa-icon>
    }
  </div>
  @if ((this.control().touched || this.dirty()) && this.control().invalid) {
    <ui-error
      [title]="title()"
      [isErrorIcon]="false"
      [fieldName]="label()"
      [errorList]="errors()"
      [errorText]="errors() | errorTextParser: control()"
    />
  }
</div>
