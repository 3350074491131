import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FormError } from '@data-content/form-error';
import { CustomIconsModule } from '@ui/custom-icons/custom-icons.module';
import { ErrorComponent } from '@ui/error/error.component';
import { ErrorTextParserPipe } from '@util/error-text-parser.pipe';

@Component({
  selector: 'ui-text-input',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    ErrorTextParserPipe,
    ErrorComponent,
    CustomIconsModule,
  ],
  templateUrl: './text-input.component.html',
})
export class TextInputComponent {
  control = input.required<FormControl<string | null>>();
  label = input<string>();
  errors = input<FormError[] | undefined>();
  dirty = input<boolean>();
  title = input<string>();
}
